@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
  padding: 0;
  margin: 0;
  background-color: black;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 1rem;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

h5 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}

h6 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 1rem;
}
